<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="手术预约"/>
        <div style="margin-top: 20px">
            <div class="search">
                <el-button-group>
                    <el-button type="primary" plain size="small">今天</el-button>
                    <el-button type="primary" plain icon="el-icon-arrow-left" size="small"></el-button>
                    <el-button type="primary" plain icon="el-icon-arrow-right" size="small"></el-button>
                </el-button-group>
                <div style="display: flex">
                    <el-input v-model="keywords" size="small"></el-input>
                    <el-button type="primary" plain size="small" style="margin-left:10px ">检索</el-button>
                </div>
                <div>
                    <el-button type="primary" plain size="small">日</el-button>
                    <el-button type="primary" plain size="small">月</el-button>
                </div>
            </div>
            <div>
                <el-button type="primary" size="small" @click="handleAdd">添加预约手术</el-button>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="index" width="50" align="center"/>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)"
                                   size="small"
                                   type="warning"
                                   icon="el-icon-edit">
                        </el-button>
                        <el-button @click="handlePrint(scope.row)"
                                   size="small"
                                   type="success"
                                   icon="el-icon-printer">
                        </el-button>
                        <el-button @click="handleDel(scope.row)"
                                   size="small"
                                   type="danger"
                                   icon="el-icon-delete"
                        >
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin: 20px" v-if="table.total > table.pageSize">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                               :total="table.total" :page-size="table.pageSize"
                               @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
        <el-dialog
            title="预约手术"
                :visible.sync="model2"
                width="40%"
                @opened="initForm"

        >
            <div id="printTest">
                <div v-html="pdataform.content"></div>
                <div class="baidumap">
                    <baidu-map :scroll-wheel-zoom="true" :center="center" :zoom="zoom"
                               @ready="handlerBMap">
                        <bm-view style="width: 100%; height:330px; flex: 1"></bm-view>
                    </baidu-map>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="model2 = false">取 消</el-button>
              <el-button type="primary" v-print="'#printTest'">打 印</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="预约手术"
                :visible.sync="model1"
                width="80%"
                center
        >
            <el-form :model="dataform" :rules="rules" ref="dataform" label-width="120px">
                <el-row>
                    <el-col :span="24">
                        <el-col :span="12">
                            <el-form-item label="预约日期">
                                <span>2021年10月03日</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="手术地点">
                                <el-select v-model="dataform.doctor" placeholder="请选择" style="width:80%">
                                    <el-option :label="item.name" :value="item.id" v-for="item in doctorList"
                                               :key="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-col :span="12">
                            <el-form-item label="患者姓名">
                                <el-input v-model="dataform.patientName" style="width:80%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="预约时间">
                                <el-select v-model="dataform.time" placeholder="请选择" style="width:80%">
                                    <el-option :label="item.name" :value="item.value" v-for="item in timeList"
                                               :key="item.value" :disabled="item.disabled">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-col :span="12">
                            <el-form-item label="初步诊断">
                                <el-input v-model="dataform.diagnose" style="width:80%"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话">
                                <el-input v-model="dataform.phone" style="width:80%"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <!--                <el-row>-->
                <!--                    <el-col :span="24">-->
                <!--                        <el-col :span="12">-->
                <!--                            <el-form-item label="预计治疗">-->
                <!--                                <el-input v-model="dataform.treat" type="textarea" style="width:80%" :rows="4"-->
                <!--                                          placeholder="请输入">-->
                <!--                                </el-input>-->
                <!--                            </el-form-item>-->
                <!--                        </el-col>-->
                <!--                        <el-col :span="12">-->
                <!--                            <el-form-item label="开单医生">-->
                <!--                                <el-input v-model="dataform.billingDoctor" style="width:80%"></el-input>-->
                <!--                            </el-form-item>-->
                <!--                        </el-col>-->
                <!--                    </el-col>-->
                <!--                </el-row>-->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="手术类型">
                            <el-radio-group v-model="dataform.optype">
                                <el-radio :label="1">普通手术</el-radio>
                                <el-radio :label="2">特需手术</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input v-model="dataform.remarks" type="textarea" style="width:90%" :rows="4"
                                      placeholder="请输入">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="model1 = false">取 消</el-button>
                <el-button type="primary" @click="addSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    export default {
        data() {
            return {
                loading: false,
                table: {
                    columns: [
                        {title: '手术医生', field: 'doctor', width: ''},
                        {title: '预约时间', field: 'time', width: '200'},
                        {title: '患者姓名', field: 'patientName', width: ''},
                        {title: '联系电话', field: 'phone', width: ''},
                        {title: '初步诊断', field: 'diagnose', width: ''},
                        {title: '预计治疗', field: 'treat', width: ''},
                        {title: '开单医生', field: 'billingDoctor', width: ''},
                        {title: '备注', field: 'remarks', width: ''},
                    ],
                    doctor: "李倩西",
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
                keywords: '',
                model1: false,
                model2: false,
                dataform: {
                    optype: 1,
                    doctor: '',
                    remarks: '',
                    billingDoctor: '',
                    phone: '',
                    diagnose: '',
                    patientName: '',
                    time: '',
                    treat: '',
                    longitude: 116.3869,
                    latitude: 39.939606
                },
                BMap: null,
                map: null,
                lng:116.3869,
                lat:39.939606,
                center: {lng: 109.45744048529967, lat: 36.49771311230842},
                zoom: 13,
                pdataform: {
                    content: '<h3 class="ql-align-center">北京大学第一医院</h3><h2 class="ql-align-center">皮肤科手术须知</h2><p>尊敬的<s><u>郭玲君</u></s>患者，请您于<s>2021年12月07日08时00分</s>前往皮肤科手术室接受手术，地点位于西什库大街8号北京大学第一医院急诊楼三楼皮肤科手术室，</p><p>联系电话:8357310383573273</p><ul><li>如不能按时前来，请<u>提前3日</u>电话取消手术，然后择期自行办理退费手续。</li><li>目前所付只是基本手术费用，手术医生根据具体情况可能还会增加收费。</li><li>由于手术情况复杂，前面的手术可能无法按时完成，请您耐心等待请于术前1日洗头、洗澡，手术当日穿着开身宽松衣物。</li><li>服用阿司匹林、银杏叶制品、华法令等药物的患者，如是一般预防用药，请停药2周再行手术，如因疾病不能停药，务必术前向手术医师说明。</li><li>术前1周请不要吸烟、饮酒。</li></ul>',
                    address: '北京大学第一医院急诊楼三楼皮肤科手术室'
                },
                timeList: [
                    {
                        id: 1,
                        name: "08:00-08:30",
                        value: "08:00-08:30",
                        disabled: true,
                    },
                    {
                        id: 2,
                        name: "08:30-09:00",
                        value: "08:30-09:00",
                    },
                    {
                        id: 3,
                        name: "09:00-09:30",
                        value: "09:00-09:30",
                        disabled: true,
                    },
                    {
                        id: 4,
                        name: "09:30-10:00",
                        value: "09:30-10:00",
                    },
                    {
                        id: 5,
                        name: "10:00-10:30",
                        value: "10:00-10:30",
                    },
                ],
                doctorList: [
                    {
                        id: 1,
                        name: "门诊手术室"
                    },
                    {
                        id: 2,
                        name: "住院2部手术室"
                    },
                ],
                rules: {
                    doctor: [
                        {required: true, message: '请输入', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            initForm(){
                var that = this
                that.makerCenter(that.dataform.longitude, that.dataform.latitude)
            },
            handlerBMap({BMap, map}) {
                var that = this
                this.BMap = BMap
                this.map = map
                that.makerCenter(that.dataform.longitude, that.dataform.latitude)
            },
            makerCenter(lon, lat) {
                var that = this
                if (that.map && lon) {
                    that.map.clearOverlays()
                    var pt = new that.BMap.Point(lon, lat);
                    // eslint-disable-next-line no-undef
                    var marker = new BMap.Marker(pt);
                    that.map.addOverlay(marker)
                    that.center.lng = lon
                    that.center.lat = lat
                    that.zoom = 15
                    // eslint-disable-next-line no-undef
                    var label = new BMap.Label(that.pdataform.address, {offset: new BMap.Size(20, 5)});
                    label.setStyle({
                        color: "#444",
                        borderRadius: '5px',
                        borderColor: '#444',
                        background:'#fff',
                        padding: '5px',
                        fontSize: '13px',
                        textAlign:"center",
                        lineHeight:"25px",
                        fontFamily: '微软雅黑'
                    });
                    marker.setLabel(label);
                }
            },
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.model1 = true
            },
            handleUpdate() {
                this.model1 = true
            },
            handlePrint(){
                this.model2 = true
            },
            addSubmit() {

            },
            async getData() {
                this.loading = true
                this.table.data = [
                    {
                        doctor: '张医生',
                        remarks: '测试',
                        billingDoctor: '李医生',
                        phone: '13764691633',
                        diagnose: '',
                        patientName: '李四',
                        time: '2021-08-06',
                        treat: ''
                    }
                ]
                this.table.total = 3
                this.loading = false
            },
            handleSearch(e) {
                console.log(e)
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    .search {
        display: flex;
        justify-content: space-between;
        padding-bottom: 40px;
        /*border-bottom: 1px solid #EBEEF5;*/
    }

    .doctor {
        padding-top: 10px;
    }

    /deep/ .el-dialog__title {
        font-size: 23px;
        font-weight: bold;
    }

    .tail {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    /deep/ .baidumap .BMap_cpyCtrl {
        display: none !important;
    }
    /deep/  .baidumap .anchorBL {
        display: none !important;
    }
    /deep/ .ql-align-center{
        text-align: center;
        margin: auto;
        padding: 5px;
    }
    /deep/ ul{
        margin: auto;
        padding: 5px 20px;
    }
    /deep/ p{
        margin: auto;
        padding: 2px 0px;
        text-indent: 2em;
    }
</style>
